import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import SignIn from './pages/SignIn';
import SavedResults from './pages/SavedResults';
import Header from './components/Header';
import Footer from './components/Footer';
import Sidebar from './components/Sidebar';
import './App.css';

const App = () => {
  const [userProfile, setUserProfile] = useState(null);

  useEffect(() => {
    const storedUserProfile = localStorage.getItem('userProfile');
    if (storedUserProfile) {
      setUserProfile(JSON.parse(storedUserProfile));
      console.log('Loaded userProfile from localStorage:', JSON.parse(storedUserProfile));
    } else {
      console.log('No userProfile found in localStorage.');
    }
  }, []);

  useEffect(() => {
    // Add an event listener for storage changes to update userProfile if it changes in another tab
    const handleStorageChange = () => {
      const storedUserProfile = localStorage.getItem('userProfile');
      if (storedUserProfile) {
        setUserProfile(JSON.parse(storedUserProfile));
        console.log('Updated userProfile from localStorage change:', JSON.parse(storedUserProfile));
      } else {
        setUserProfile(null);
        console.log('userProfile removed from localStorage.');
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  return (
    <Router>
      <div className="app">
        <Header />
        <Sidebar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route 
              path="/saved-results" 
              element={userProfile ? <SavedResults /> : <SignIn />}
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
