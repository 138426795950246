// src/components/Sidebar.js
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from 'react-icons/fa';
import './Sidebar.css';

const Sidebar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
    if (!isOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
  };

  return (
    <div>
      <div className="hamburger" onClick={toggleSidebar}>
        <FaBars />
      </div>
      <div className={`sidebar ${isOpen ? 'active' : ''}`}>
        <nav className="nav-menu">
          <ul>
            <li>
              <Link to="/" className="nav-item" onClick={toggleSidebar}>
                Home
              </Link>
            </li>
            <li>
              <Link to="/savedresults" className="nav-item" onClick={toggleSidebar}>
                Saved Results
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      <div className={`overlay ${isOpen ? 'open' : ''}`} onClick={toggleSidebar}></div>
    </div>
  );
};

export default Sidebar;
