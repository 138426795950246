// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, enableIndexedDbPersistence } from 'firebase/firestore';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAbQQEx5lrDI8sBw2sCc4OgL8iX5ZNsqkI",
  authDomain: "paycheck-calculator-427207.firebaseapp.com",
  databaseURL: "https://paycheck-calculator-427207-default-rtdb.firebaseio.com",
  projectId: "paycheck-calculator-427207",
  storageBucket: "paycheck-calculator-427207.appspot.com",
  messagingSenderId: "193412132746",
  appId: "1:193412132746:web:7068b249a42ce0a18da273",
  measurementId: "G-6885Z54PK2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();

// Enable offline persistence
enableIndexedDbPersistence(db)
  .catch((err) => {
    if (err.code == 'failed-precondition') {
      // Multiple tabs open, persistence can only be enabled
      // in one tab at a a time.
      console.log("Persistence failed");
    } else if (err.code == 'unimplemented') {
      // The current browser does not support all of the
      // features required to enable persistence
      console.log("Persistence is not available");
    }
  });

export { db, auth, googleProvider };
