// src/contexts/FormContext.js
import React, { createContext, useState } from 'react';

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState({
    filingStatus: '',
    payrollPeriod: '',
    state: '',
    qualifyingChildren: '',
    otherDependents: '',
    hourlyWage: '',
    hoursWorked: '',
    compensationTitle: '',
    hourlyRate: '',
    hoursWorkedComp: '',
    pre401kPercent: '',
    preTaxName: '',
    preTaxAmount: '',
    roth401kPercent: '',
    afterTaxName: '',
    afterTaxAmount: '',
    imputedIncomeName: '',
    imputedIncomeAmount: '',
  });

  return (
    <FormContext.Provider value={{ formData, setFormData }}>
      {children}
    </FormContext.Provider>
  );
};
