import React, { useContext, useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { Toast, ToastContainer } from 'react-bootstrap';
import { FormContext } from '../contexts/FormContext';
import { db } from '../firebase';
import { doc, setDoc, getDoc, updateDoc, arrayUnion } from 'firebase/firestore';
import './Home.css';

const Home = () => {
  const { formData, setFormData } = useContext(FormContext);
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [compensations, setCompensations] = useState([]);
  const [preTaxDeductions, setPreTaxDeductions] = useState([]);
  const [afterTaxDeductions, setAfterTaxDeductions] = useState([]);
  const [pre401kDeductions, setPre401kDeductions] = useState([]);
  const [roth401kDeductions, setRoth401kDeductions] = useState([]);
  const [imputedIncomes, setImputedIncomes] = useState([]);
  const [taxBreakdown, setTaxBreakdown] = useState(null);
  const [netPay, setNetPay] = useState(null);
  const [grossPay, setGrossPay] = useState(null);

  useEffect(() => {
    const fetchFormData = async () => {
      const userProfile = JSON.parse(localStorage.getItem('userProfile'));
      if (!userProfile) return;

      try {
        const docRef = doc(db, 'users', userProfile.sub);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data().formData;
          setFormData(data);
          setCompensations(data.compensations || []);
          setPreTaxDeductions(data.preTaxDeductions || []);
          setAfterTaxDeductions(data.afterTaxDeductions || []);
          setPre401kDeductions(data.pre401kDeductions || []);
          setRoth401kDeductions(data.roth401kDeductions || []);
          setImputedIncomes(data.imputedIncomes || []);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      }
    };

    fetchFormData();
  }, [setFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const validateForm = () => {
    let valid = true;
    const requiredFields = ['filingStatus', 'payrollPeriod', 'hourlyWage', 'hoursWorked'];
    requiredFields.forEach(field => {
      if (!formData[field]) {
        valid = false;
        setToastMessage(`Please fill in the ${field.replace(/([A-Z])/g, ' $1').toLowerCase()} field`);
        setShowToast(true);
      }
    });
    return valid;
  };

  const calculateStateTax = (taxableEarnings, selectedState) => {
    const stateTaxRates = {
      'Arizona': 0.025,
      'Colorado': 0.025,
      'Idaho': 0.035,
      'Illinois': 0.0495,
      'Indiana': 0.0305,
      'Kentucky': 0.04,
      'Michigan': 0.0425,
      'North Carolina': 0.045,
      'Pennsylvania': 0.0307,
      'Utah': 0.0485,
    };
    return stateTaxRates[selectedState] ? taxableEarnings * stateTaxRates[selectedState] : 0;
  };

  const calculateFederalTax = (taxableEarnings, filingStatus, payrollPeriod, qualifyingChildren, otherDependents) => {
    const brackets = {
      single: {
        weekly: [0, 281, 504, 1188, 2214, 3972, 4968, 11999],
        biweekly: [0, 562, 1008, 2375, 4428, 7944, 9936, 23998],
        semimonthly: [0, 608, 1092, 2573, 4797, 8606, 10764, 25998],
        monthly: [0, 2433, 4367, 10292, 19188, 34425, 43054, 63367]
      },
      marriedSeparately: {
        weekly: [0, 281, 504, 1188, 2214, 3972, 4968, 11999],
        biweekly: [0, 562, 1008, 2375, 4428, 7944, 9936, 23998],
        semimonthly: [0, 608, 1092, 2573, 4797, 8606, 10764, 25998],
        monthly: [0, 2433, 4367, 10292, 19188, 34425, 43054, 63367]
      },
      marriedJointly: {
        weekly: [0, 562, 1008, 2375, 4428, 7944, 9936, 14623],
        biweekly: [0, 1123, 2015, 4750, 8856, 15888, 19871, 29246],
        semimonthly: [0, 1217, 2183, 5146, 9594, 17213, 21527, 31683],
        monthly: [0, 2433, 4367, 10292, 19188, 34425, 43054, 63367]
      },
      headOfHousehold: {
        weekly: [0, 421, 739, 1635, 2354, 4113, 5108, 12139],
        biweekly: [0, 842, 1479, 3269, 4708, 8225, 10215, 24279],
        semimonthly: [0, 913, 1602, 3542, 5100, 8910, 11068, 26302],
        monthly: [0, 1825, 3204, 7083, 10200, 17821, 22133, 52604]
      }
    };

    const tentative = {
      single: {
        weekly: [0, 0, 22.3, 104.38, 330.1, 752.02, 1070.74, 3531.59],
        biweekly: [0, 0, 44.6, 208.64, 660.3, 1504.14, 2141.58, 7063.28],
        semimonthly: [0, 0, 48.4, 226.12, 715.4, 1629.56, 2320.12, 7652.02],
        monthly: [0, 0, 96.6, 452.16, 1430.72, 3259.28, 4639.76, 15303.91]
      },
      marriedSeparately: {
        weekly: [0, 0, 22.3, 104.38, 330.1, 752.02, 1070.74, 3531.59],
        biweekly: [0, 0, 44.6, 208.64, 660.3, 1504.14, 2141.58, 7063.28],
        semimonthly: [0, 0, 48.4, 226.12, 715.4, 1629.56, 2320.12, 7652.02],
        monthly: [0, 0, 96.6, 452.16, 1430.72, 3259.28, 4639.76, 15303.91]
      },
      marriedJointly: {
        weekly: [0, 0, 44.6, 208.64, 660.3, 1504.14, 2141.58, 3782.03],
        biweekly: [0, 0, 89.2, 417.4, 1320.72, 3008.4, 4282.96, 7564.21],
        semimonthly: [0, 0, 96.6, 452.16, 1430.72, 3259.28, 4639.76, 8194.36],
        monthly: [0, 0, 193.4, 904.4, 2861.52, 6518.4, 9279.68, 16389.23]
      },
      headOfHousehold: {
        weekly: [0, 0, 31.8, 139.32, 297.5, 719.66, 1038.06, 3498.91],
        biweekly: [0, 0, 63.7, 278.5, 595.08, 1439.16, 2075.96, 6998.36],
        semimonthly: [0, 0, 68.9, 301.7, 644.46, 1558.86, 2249.1, 7581.35],
        monthly: [0, 0, 137.9, 603.38, 1289.12, 3118.16, 4498.0, 15162.85]
      }
    };

    const selectedBrackets = brackets[filingStatus][payrollPeriod];
    const selectedTentative = tentative[filingStatus][payrollPeriod];

    if (taxableEarnings <= selectedBrackets[1]) {
      return (taxableEarnings - selectedBrackets[0]) * 0;
    } else if (taxableEarnings <= selectedBrackets[2]) {
      return selectedTentative[1] + ((taxableEarnings - selectedBrackets[1]) * 0.10);
    } else if (taxableEarnings <= selectedBrackets[3]) {
      return selectedTentative[2] + ((taxableEarnings - selectedBrackets[2]) * 0.12);
    } else if (taxableEarnings <= selectedBrackets[4]) {
      return selectedTentative[3] + ((taxableEarnings - selectedBrackets[3]) * 0.22);
    } else if (taxableEarnings <= selectedBrackets[5]) {
      return selectedTentative[4] + ((taxableEarnings - selectedBrackets[4]) * 0.24);
    } else if (taxableEarnings <= selectedBrackets[6]) {
      return selectedTentative[5] + ((taxableEarnings - selectedBrackets[5]) * 0.32);
    } else if (taxableEarnings <= selectedBrackets[7]) {
      return selectedTentative[6] + ((taxableEarnings - selectedBrackets[6]) * 0.35);
    } else {
      return selectedTentative[7] + ((taxableEarnings - selectedBrackets[7]) * 0.37);
    }
  };

  const getNumberOfPayPeriods = (payrollPeriod) => {
    switch (payrollPeriod) {
      case 'monthly':
        return 12;
      case 'semimonthly':
        return 24;
      case 'biweekly':
        return 26;
      case 'weekly':
        return 52;
      case 'daily':
        return 260;
      default:
        return 0;
    }
  };

  const calculatePaycheck = (e) => {
    e.preventDefault();
    if (!validateForm()) return;
    const hourlyWage = parseFloat(formData.hourlyWage);
    const hoursWorked = parseFloat(formData.hoursWorked);

    if (isNaN(hourlyWage) || isNaN(hoursWorked)) {
      setToastMessage("Please enter valid values for Hourly Wage and Hours Worked.");
      setShowToast(true);
      return;
    }

    const selectedState = formData.state;
    const regularEarnings = hourlyWage * hoursWorked;
    const additionalEarnings = compensations.reduce((total, type) => total + parseFloat(type.hourlyRate) * parseFloat(type.hoursWorkedComp), 0);
    const totalEarnings = regularEarnings + additionalEarnings;

    const pre401kPercent = formData.pre401kPercent ? parseFloat(formData.pre401kPercent) / 100 : 0;
    const pre401kDeduction = pre401kPercent * totalEarnings;

    const roth401kPercent = formData.roth401kPercent ? parseFloat(formData.roth401kPercent) / 100 : 0;
    const roth401kDeduction = roth401kPercent * totalEarnings;

    const addedPreTaxDeductions = preTaxDeductions.reduce((total, deduction) => total + parseFloat(deduction.amount), 0);
    const totalPreTaxDeductions = pre401kDeduction + addedPreTaxDeductions;

    const addedAfterTaxDeductions = afterTaxDeductions.reduce((total, deduction) => total + parseFloat(deduction.amount), 0);
    const totalAfterTaxDeductions = roth401kDeduction + addedAfterTaxDeductions;

    const totalImputedIncomes = imputedIncomes.reduce((total, income) => total + parseFloat(income.amount), 0);
    const taxableEarnings = (totalEarnings - totalPreTaxDeductions) + totalImputedIncomes;

    const stateTax = calculateStateTax(taxableEarnings, selectedState);
    const qualifyingChildren = parseInt(formData.qualifyingChildren, 10) || 0;
    const otherDependents = parseInt(formData.otherDependents, 10) || 0;

    const filingStatus = formData.filingStatus;
    const payrollPeriod = formData.payrollPeriod;

    const childTaxCredit = 2000;
    const otherDependentsAmount = 500;

    const dependents = (childTaxCredit * qualifyingChildren + otherDependentsAmount * otherDependents) / getNumberOfPayPeriods(payrollPeriod);
    const federalTax = calculateFederalTax(taxableEarnings, filingStatus, payrollPeriod, qualifyingChildren, otherDependents);
    const adjustedFederalTax = federalTax - dependents;

    const ssnMedicareTaxableEarnings = taxableEarnings + pre401kDeduction;
    const socialSecurityTax = 0.062 * ssnMedicareTaxableEarnings;
    const medicareTax = 0.0145 * ssnMedicareTaxableEarnings;

    const taxBreakdown = {
      federalTax,
      stateTax,
      socialSecurityTax,
      medicareTax,
      preTaxDeductionTotal: totalPreTaxDeductions,
      afterTaxDeductionTotal: totalAfterTaxDeductions,
      imputedIncomeTotal: totalImputedIncomes
    };
    const netPay = totalEarnings - totalPreTaxDeductions - adjustedFederalTax - stateTax - socialSecurityTax - medicareTax - totalAfterTaxDeductions;
    const grossPay = totalEarnings;

    setTaxBreakdown(taxBreakdown);
    setNetPay(netPay);
    setGrossPay(grossPay);
    setToastMessage(`Gross Pay: $${grossPay.toFixed(2)}, Net Pay: $${netPay.toFixed(2)}`);
    setShowToast(true);

    // Save calculated results
    saveResults(taxBreakdown, netPay, grossPay);
  };

  const addCompensationType = () => {
    setCompensations([
      ...compensations,
      {
        title: formData.compensationTitle,
        hourlyRate: formData.hourlyRate,
        hoursWorkedComp: formData.hoursWorkedComp
      }
    ]);
    setFormData({
      ...formData,
      compensationTitle: '',
      hourlyRate: '',
      hoursWorkedComp: ''
    });
  };

  const removeCompensationType = (index) => {
    const newCompensations = [...compensations];
    newCompensations.splice(index, 1);
    setCompensations(newCompensations);
  };

  const addPreTaxDeduction = () => {
    setPreTaxDeductions([
      ...preTaxDeductions,
      {
        name: formData.preTaxName,
        amount: parseFloat(formData.preTaxAmount)
      }
    ]);
    setFormData({
      ...formData,
      preTaxName: '',
      preTaxAmount: ''
    });
  };

  const removePreTaxDeduction = (index) => {
    const newPreTaxDeductions = [...preTaxDeductions];
    newPreTaxDeductions.splice(index, 1);
    setPreTaxDeductions(newPreTaxDeductions);
  };

  const addAfterTaxDeduction = () => {
    setAfterTaxDeductions([
      ...afterTaxDeductions,
      {
        name: formData.afterTaxName,
        amount: parseFloat(formData.afterTaxAmount)
      }
    ]);
    setFormData({
      ...formData,
      afterTaxName: '',
      afterTaxAmount: ''
    });
  };

  const removeAfterTaxDeduction = (index) => {
    const newAfterTaxDeductions = [...afterTaxDeductions];
    newAfterTaxDeductions.splice(index, 1);
    setAfterTaxDeductions(newAfterTaxDeductions);
  };

  const addImputedIncome = () => {
    setImputedIncomes([
      ...imputedIncomes,
      {
        name: formData.imputedIncomeName,
        amount: parseFloat(formData.imputedIncomeAmount)
      }
    ]);
    setFormData({
      ...formData,
      imputedIncomeName: '',
      imputedIncomeAmount: ''
    });
  };

  const removeImputedIncome = (index) => {
    const newImputedIncomes = [...imputedIncomes];
    newImputedIncomes.splice(index, 1);
    setImputedIncomes(newImputedIncomes);
  };

  const saveFormData = async () => {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    if (!userProfile) {
      setToastMessage("Please sign in to save your data.");
      setShowToast(true);
      return;
    }

    const dataToSave = {
      ...formData,
      compensations,
      preTaxDeductions,
      afterTaxDeductions,
      pre401kDeductions,
      roth401kDeductions,
      imputedIncomes,
      date: new Date(),
      grossPay,
      netPay,
      taxBreakdown
    };

    console.log('Data to save:', dataToSave);

    try {
      const docRef = doc(db, 'users', userProfile.sub);
      const docSnap = await getDoc(docRef);

      console.log('Document snapshot:', docSnap);

      if (docSnap.exists()) {
        const userData = docSnap.data();
        console.log('User data:', userData);

        const updatedResults = [...(userData.savedResults || []), dataToSave];
        await setDoc(docRef, { savedResults: updatedResults }, { merge: true });
      } else {
        await setDoc(docRef, { savedResults: [dataToSave] });
      }

      setToastMessage("Form data saved successfully.");
      setShowToast(true);
    } catch (error) {
      console.error("Error saving form data:", error);
      setToastMessage("Failed to save form data.");
      setShowToast(true);
    }
  };

  const saveResults = async (taxBreakdown, netPay, grossPay) => {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    if (!userProfile) {
      setToastMessage("Please sign in to save your results.");
      setShowToast(true);
      return;
    }

    const resultsToSave = {
      date: new Date(),
      taxBreakdown,
      netPay,
      grossPay
    };

    try {
      const docRef = doc(db, 'users', userProfile.sub);
      await updateDoc(docRef, {
        savedResults: arrayUnion(resultsToSave)
      });
      setToastMessage("Results saved successfully.");
      setShowToast(true);
    } catch (error) {
      console.error("Error saving results:", error);
      setToastMessage("Failed to save results.");
      setShowToast(true);
    }
  };

  const fetchSavedFormData = async () => {
    const userProfile = JSON.parse(localStorage.getItem('userProfile'));
    if (!userProfile) {
      setToastMessage("Please sign in to fetch your data.");
      setShowToast(true);
      return;
    }

    try {
      const docRef = doc(db, 'users', userProfile.sub);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        if (data.savedResults && data.savedResults.length > 0) {
          const latestSavedData = data.savedResults[data.savedResults.length - 1];
          setFormData(latestSavedData);
          setCompensations(latestSavedData.compensations || []);
          setPreTaxDeductions(latestSavedData.preTaxDeductions || []);
          setAfterTaxDeductions(latestSavedData.afterTaxDeductions || []);
          setPre401kDeductions(latestSavedData.pre401kDeductions || []);
          setRoth401kDeductions(latestSavedData.roth401kDeductions || []);
          setImputedIncomes(latestSavedData.imputedIncomes || []);
          setTaxBreakdown(latestSavedData.taxBreakdown);
          setNetPay(latestSavedData.netPay);
          setGrossPay(latestSavedData.grossPay);
        } else {
          setToastMessage("No saved data found.");
          setShowToast(true);
        }
      } else {
        setToastMessage("No user data found.");
        setShowToast(true);
      }
    } catch (error) {
      console.error("Error fetching saved form data:", error);
      setToastMessage("Failed to fetch saved data.");
      setShowToast(true);
    }
  };

  const resetForm = () => {
    setFormData({
      filingStatus: '',
      payrollPeriod: '',
      state: '',
      qualifyingChildren: '',
      otherDependents: '',
      hourlyWage: '',
      hoursWorked: '',
      compensationTitle: '',
      hourlyRate: '',
      hoursWorkedComp: '',
      pre401kPercent: '',
      preTaxName: '',
      preTaxAmount: '',
      roth401kPercent: '',
      afterTaxName: '',
      afterTaxAmount: '',
      imputedIncomeName: '',
      imputedIncomeAmount: ''
    });
    setCompensations([]);
    setPreTaxDeductions([]);
    setAfterTaxDeductions([]);
    setPre401kDeductions([]);
    setRoth401kDeductions([]);
    setImputedIncomes([]);
    setTaxBreakdown(null);
    setNetPay(null);
    setGrossPay(null);
  };

  return (
    <div className="container mt-5">
      <Helmet>
        <title>Paycheck Calculator</title>
        <meta name="description" content="Calculate your paycheck easily with our Paycheck Calculator." />
      </Helmet>
      <div className="card">
        <div className="card-body">
          <form id="paycheckForm" onSubmit={calculatePaycheck}>
            <div className="form-row">
              <div className="col-md-4">
                <fieldset className="mb-3">
                  <legend className="legend">Filing Status</legend>
                  <select className="form-select" id="filingStatus" name="filingStatus" value={formData.filingStatus} onChange={handleChange}>
                    <option value="">Select...</option>
                    <option value="single">Single</option>
                    <option value="marriedJointly">Married Filing Jointly</option>
                    <option value="marriedSeparately">Married Filing Separately</option>
                    <option value="headOfHousehold">Head of Household</option>
                  </select>
                </fieldset>
              </div>
              <div className="col-md-4">
                <fieldset className="mb-3">
                  <legend className="legend">Payroll Period</legend>
                  <select className="form-select" id="payrollPeriod" name="payrollPeriod" value={formData.payrollPeriod} onChange={handleChange}>
                    <option value="">Select...</option>
                    <option value="weekly">Weekly</option>
                    <option value="biweekly">Biweekly</option>
                    <option value="semimonthly">SemiMonthly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </fieldset>
              </div>
              <div className="col-md-4">
                <fieldset className="mb-3">
                  <legend className="legend">State</legend>
                  <select className="form-select" id="state" name="state" value={formData.state} onChange={handleChange}>
                    <option value="None">None</option>
                    <option value="Arizona">Arizona</option>
                    <option value="Colorado">Colorado</option>
                    <option value="Idaho">Idaho</option>
                    <option value="Illinois">Illinois</option>
                    <option value="Indiana">Indiana</option>
                    <option value="Kentucky">Kentucky</option>
                    <option value="Michigan">Michigan</option>
                    <option value="North Carolina">North Carolina</option>
                    <option value="Pennsylvania">Pennsylvania</option>
                    <option value="Utah">Utah</option>
                  </select>
                </fieldset>
              </div>
            </div>
            <fieldset className="mb-3">
              <legend className="legend">Dependents</legend>
              <div className="floating-label-group mb-3">
                <input type="number" className="floating-label-input" id="qualifyingChildren" name="qualifyingChildren" value={formData.qualifyingChildren} onChange={handleChange} placeholder=" " />
                <label htmlFor="qualifyingChildren" className="floating-label">Number of Qualifying Children (under 17)</label>
              </div>
              <div className="floating-label-group mb-3">
                <input type="number" className="floating-label-input" id="otherDependents" name="otherDependents" value={formData.otherDependents} onChange={handleChange} placeholder=" " />
                <label htmlFor="otherDependents" className="floating-label">Number of Other Dependents</label>
              </div>
            </fieldset>
            <div className="row">
              <div className="col-md-6">
                <fieldset className="mb-3">
                  <legend className="legend">Earnings</legend>
                  <div className="floating-label-group mb-2">
                    <input type="number" className="floating-label-input" id="hourlyWage" name="hourlyWage" value={formData.hourlyWage} onChange={handleChange} placeholder=" " />
                    <label htmlFor="hourlyWage" className="floating-label">Enter Hourly Wage</label>
                  </div>
                  <div className="floating-label-group mb-2">
                    <input type="number" className="floating-label-input" id="hoursWorked" name="hoursWorked" value={formData.hoursWorked} onChange={handleChange} placeholder=" " />
                    <label htmlFor="hoursWorked" className="floating-label">Enter Hours Worked</label>
                  </div>
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset className="mb-3">
                  <legend className="legend">Additional Earnings</legend>
                  <div className="floating-label-group mb-2">
                    <input type="text" className="floating-label-input" id="compensationTitle" name="compensationTitle" value={formData.compensationTitle} onChange={handleChange} placeholder=" " />
                    <label htmlFor="compensationTitle" className="floating-label">Enter Compensation Title</label>
                  </div>
                  <div className="floating-label-group mb-2">
                    <input type="number" className="floating-label-input" id="hourlyRate" name="hourlyRate" value={formData.hourlyRate} onChange={handleChange} placeholder=" " />
                    <label htmlFor="hourlyRate" className="floating-label">Enter Hourly Rate</label>
                  </div>
                  <div className="floating-label-group mb-2">
                    <input type="number" className="floating-label-input" id="hoursWorkedComp" name="hoursWorkedComp" value={formData.hoursWorkedComp} onChange={handleChange} placeholder=" " />
                    <label htmlFor="hoursWorkedComp" className="floating-label">Enter Hours Worked</label>
                  </div>
                  <button type="button" className="btn add-btn mb-3" onClick={addCompensationType}>+</button>
                  <div id="compensationTypesContainer">
                    {compensations.map((comp, index) => (
                      <div key={index} className="alert-input alert" role="alert">
                        {comp.title}: ${comp.hourlyRate} x {comp.hoursWorkedComp} hours
                        <button type="button" className="btn remove-btn" onClick={() => removeCompensationType(index)}>-</button>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <fieldset className="mb-3">
                  <legend className="legend">Pre-Tax Deductions</legend>
                  <div className="floating-label-group mb-2">
                    <input type="number" className="floating-label-input" id="pre401kPercent" name="pre401kPercent" value={formData.pre401kPercent} onChange={handleChange} placeholder=" " />
                    <label htmlFor="pre401kPercent" className="floating-label">Enter pre-401k contribution %</label>
                  </div>
                  <div id="pre401kDeductionsContainer">
                    {pre401kDeductions.map((deduction, index) => (
                      <div key={index} className="alert-input alert" role="alert">
                        Pre-401k: {deduction.percent}%
                      </div>
                    ))}
                  </div>
                  <div className="floating-label-group mb-2">
                    <input type="text" className="floating-label-input" id="preTaxName" name="preTaxName" value={formData.preTaxName} onChange={handleChange} placeholder=" " />
                    <label htmlFor="preTaxName" className="floating-label">Enter pre-tax Deduction Name</label>
                  </div>
                  <div className="floating-label-group mb-2">
                    <input type="number" className="floating-label-input" id="preTaxAmount" name="preTaxAmount" value={formData.preTaxAmount} onChange={handleChange} placeholder=" " />
                    <label htmlFor="preTaxAmount" className="floating-label">Enter Deduction Amount</label>
                  </div>
                  <button type="button" className="btn add-btn mb-3" onClick={addPreTaxDeduction}>+</button>
                  <div id="preTaxDeductionsContainer">
                    {preTaxDeductions.map((deduction, index) => (
                      <div key={index} className="alert-input alert" role="alert">
                        {deduction.name}: ${deduction.amount}
                        <button type="button" className="btn remove-btn" onClick={() => removePreTaxDeduction(index)}>-</button>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
              <div className="col-md-6">
                <fieldset className="mb-3">
                  <legend className="legend">After-Tax Deductions</legend>
                  <div className="floating-label-group mb-2">
                    <input type="number" className="floating-label-input" id="roth401kPercent" name="roth401kPercent" value={formData.roth401kPercent} onChange={handleChange} placeholder=" " />
                    <label htmlFor="roth401kPercent" className="floating-label">Enter roth-401k contribution %</label>
                  </div>
                  <div id="roth401kDeductionsContainer">
                    {roth401kDeductions.map((deduction, index) => (
                      <div key={index} className="alert-input alert" role="alert">
                        Roth-401k: {deduction.percent}%
                      </div>
                    ))}
                  </div>
                  <div className="floating-label-group mb-2">
                    <input type="text" className="floating-label-input" id="afterTaxName" name="afterTaxName" value={formData.afterTaxName} onChange={handleChange} placeholder=" " />
                    <label htmlFor="afterTaxName" className="floating-label">Enter After tax Deduction Name</label>
                  </div>
                  <div className="floating-label-group mb-2">
                    <input type="number" className="floating-label-input" id="afterTaxAmount" name="afterTaxAmount" value={formData.afterTaxAmount} onChange={handleChange} placeholder=" " />
                    <label htmlFor="afterTaxAmount" className="floating-label">Enter Deduction Amount</label>
                  </div>
                  <button type="button" className="btn add-btn mb-3" onClick={addAfterTaxDeduction}>+</button>
                  <div id="afterTaxDeductionsContainer">
                    {afterTaxDeductions.map((deduction, index) => (
                      <div key={index} className="alert-input alert" role="alert">
                        {deduction.name}: ${deduction.amount}
                        <button type="button" className="btn remove-btn" onClick={() => removeAfterTaxDeduction(index)}>-</button>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            </div>
            <fieldset className="mb-3">
              <legend className="legend">Imputed Income</legend>
              <div className="floating-label-group mb-2">
                <input type="text" className="floating-label-input" id="imputedIncomeName" name="imputedIncomeName" value={formData.imputedIncomeName} onChange={handleChange} placeholder=" " />
                <label htmlFor="imputedIncomeName" className="floating-label">Enter Imputed Income Name</label>
              </div>
              <div className="floating-label-group mb-2">
                <input type="number" className="floating-label-input" id="imputedIncomeAmount" name="imputedIncomeAmount" value={formData.imputedIncomeAmount} onChange={handleChange} placeholder=" " />
                <label htmlFor="imputedIncomeAmount" className="floating-label">Enter Imputed Income Amount</label>
              </div>
              <button type="button" className="btn add-btn mb-3" onClick={addImputedIncome}>+</button>
              <div id="imputedIncomeContainer">
                {imputedIncomes.map((income, index) => (
                  <div key={index} className="alert-input alert" role="alert">
                    {income.name}: ${income.amount}
                    <button type="button" className="btn remove-btn" onClick={() => removeImputedIncome(index)}>-</button>
                  </div>
                ))}
              </div>
            </fieldset>
            <button type="button" className="btn btn-primary mb-3" onClick={saveFormData}>Save Form</button>
            <button type="submit" className="btn btn-success mb-3">Calculate</button>
            <button type="button" className="btn btn-secondary mb-3" onClick={resetForm} aria-label="Reset Form">Reset</button>
            <button type="button" className="btn btn-info mb-3" onClick={fetchSavedFormData}>Fetch Saved Data</button>
            <div id="results">
              {taxBreakdown && (
                <div className="alert-tax alert mt-3" role="alert">
                  <h4>Tax Breakdown</h4>
                  <p>Federal Tax: ${taxBreakdown.federalTax.toFixed(2)}</p>
                  <p>State Tax: ${taxBreakdown.stateTax.toFixed(2)}</p>
                  <p>Social Security Tax: ${taxBreakdown.socialSecurityTax.toFixed(2)}</p>
                  <p>Medicare Tax: ${taxBreakdown.medicareTax.toFixed(2)}</p>
                  <p>Pre-Tax Deductions: ${taxBreakdown.preTaxDeductionTotal.toFixed(2)}</p>
                  <p>After-Tax Deductions: ${taxBreakdown.afterTaxDeductionTotal.toFixed(2)}</p>
                  <p>Imputed Income: ${taxBreakdown.imputedIncomeTotal.toFixed(2)}</p>
                </div>
              )}
              {netPay !== null && grossPay !== null && (
                <div className="alert-tax alert mt-3" role="alert">
                  <h4>Pay Summary</h4>
                  <p>Gross Pay: ${grossPay.toFixed(2)}</p>
                  <p>Net Pay: ${netPay.toFixed(2)}</p>
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <ToastContainer position="top-end" className="p-3">
        <Toast onClose={() => setShowToast(false)} show={showToast} delay={5000} autohide>
          <Toast.Header>
            <strong className="me-auto">Notification</strong>
          </Toast.Header>
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>
    </div>
  );
};

export default Home;
