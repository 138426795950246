// src/components/Header.js
import React from 'react';
import UserProfile from './UserProfile';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar">
        <span className="navbar-brand">Paycheck Calculator</span>
        <div className="navbar-nav">
          <UserProfile />
        </div>
      </nav>
    </header>
  );
};

export default Header;
