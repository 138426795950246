import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import './SavedResults.css';

const SavedResults = () => {
  const [savedResults, setSavedResults] = useState([]);

  useEffect(() => {
    const fetchSavedResults = async () => {
      const userProfile = JSON.parse(localStorage.getItem('userProfile'));
      if (!userProfile) return;

      try {
        const docRef = doc(db, 'users', userProfile.id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          const data = docSnap.data().savedResults || [];
          setSavedResults(data);
        }
      } catch (error) {
        console.error("Error fetching saved results:", error);
      }
    };

    fetchSavedResults();
  }, []);

  return (
    <div className="container mt-5">
      <h2>Saved Results</h2>
      {savedResults.length === 0 ? (
        <p>No saved results found.</p>
      ) : (
        <div className="results-list">
          {savedResults.map((result, index) => (
            <div key={index} className="result-item">
              <h4>{new Date(result.date.seconds * 1000).toLocaleString()}</h4>
              <p>Gross Pay: ${result.grossPay.toFixed(2)}</p>
              <p>Net Pay: ${result.netPay.toFixed(2)}</p>
              <p>Federal Tax: ${result.taxBreakdown.federalTax.toFixed(2)}</p>
              <p>State Tax: ${result.taxBreakdown.stateTax.toFixed(2)}</p>
              <p>Social Security Tax: ${result.taxBreakdown.socialSecurityTax.toFixed(2)}</p>
              <p>Medicare Tax: ${result.taxBreakdown.medicareTax.toFixed(2)}</p>
              <p>Pre-Tax Deductions: ${result.taxBreakdown.preTaxDeductionTotal.toFixed(2)}</p>
              <p>After-Tax Deductions: ${result.taxBreakdown.afterTaxDeductionTotal.toFixed(2)}</p>
              <p>Imputed Income: ${result.taxBreakdown.imputedIncomeTotal.toFixed(2)}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SavedResults;
