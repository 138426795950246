// src/components/UserProfile.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './UserProfile.css';

const UserProfile = () => {
  const [userProfile, setUserProfile] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const profile = JSON.parse(localStorage.getItem('userProfile'));
    if (profile) {
      setUserProfile(profile);
    }
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('userProfile');
    setUserProfile(null);
    setDropdownOpen(false);
    navigate('/signin');
  };

  const handleSignIn = () => {
    setDropdownOpen(false);
    navigate('/signin');
  };

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  return (
    <div className="user-profile">
      <div className="profile-info" onClick={toggleDropdown}>
        <img src={userProfile?.imageUrl || '/default-profile.png'} alt="Profile" className="profile-image" />
        {dropdownOpen && (
          <div className="dropdown-menu">
            {userProfile ? (
              <button onClick={handleLogout} className="logout-button">
                <img src="/log-out.png" alt="Log out" className="logout-icon" />
                Logout
              </button>
            ) : (
              <button onClick={handleSignIn} className="signin-button">Sign In</button>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default UserProfile;
