// src/pages/SignIn.js
import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import {jwtDecode} from 'jwt-decode';
import './SignIn.css';

const SignIn = () => {
  const handleLoginSuccess = (credentialResponse) => {
    console.log('Login Success:', credentialResponse);
    const profile = jwtDecode(credentialResponse.credential);
    console.log('Decoded JWT:', profile);

    // Save the profile to localStorage
    localStorage.setItem('userProfile', JSON.stringify(profile));
    console.log('User profile saved to localStorage:', profile);

    // Redirect to the main application page
    window.location.href = "/";
  };

  const handleLoginFailure = (error) => {
    console.log('Login Failed:', error);
  };

  return (
    <GoogleOAuthProvider clientId="193412132746-d4kq4im7nm67qc0ak2sftdiokd2j2aag.apps.googleusercontent.com">
      <div className="signin-container">
        <h2>Sign In</h2>
        <GoogleLogin
          onSuccess={handleLoginSuccess}
          onError={handleLoginFailure}
        />
      </div>
    </GoogleOAuthProvider>
  );
};

export default SignIn;
